<template>
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <v-card-title class="">
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
    </v-card>
    <!-- 时间线 -->
    <v-row>
      <v-col cols="12" sm="5" offset="7" align-self="center">
        <v-text-field v-model="searchUser" solo dense class="" append-icon="mdi-magnify" label="搜索" single-line
          hide-details></v-text-field>
      </v-col>
    </v-row>
    <v-data-table :headers="tableHeaders" :items="userList" :search="searchUser" class="order-table-hover">
      <template v-slot:no-data>
        <div>暂无数据</div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getType(item.type) }}
      </template>
      <template v-slot:[`item.accountType`]="{ item }">
        {{ getAccountType(item.accountType) }}
      </template>
      <template v-slot:[`item.applyTime`]="{ item }">
        {{ paresTime(item.applyTime) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="editItem(item)" v-on="on" v-bind="attrs">
              mdi-eye
            </v-icon>
          </template>
          <span>详情</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="refuse(item.username)" v-on="on" v-bind="attrs">
              mdi-close
            </v-icon>
          </template>
          <span>拒绝</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="creatDialog" max-width="600px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="form.username" disabled label="用户名">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="邮箱" v-model="form.email" disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="手机号" v-model="form.phoneNumber" disabled></v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.name != null">
                <v-text-field label="姓名" v-model="form.name" disabled></v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.idCard != null">
                <v-text-field label="身份证" v-model="form.idCard" disabled></v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.companyName != null">
                <v-text-field label="公司名称" v-model="form.companyName" disabled>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.businessLicense != null">
                <v-text-field label="工商执照" v-model="form.businessLicense" disabled>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" v-if="form.frontPhoto != null">
                <v-img :src="form.frontPhoto" @click="showImg(form.frontPhoto)"></v-img>
                <p class="text-center caption">身份证正面照</p>
              </v-col>
              <v-col cols="12" sm="4" offset="1" v-if="form.backPhoto != null">
                <v-img :src="form.backPhoto" @click="showImg(form.backPhoto)"></v-img>
                <p class="text-center caption">身份证反面照</p>
              </v-col>
              <v-col cols="12" sm="4" v-if="form.businesslicensePhoto != null">
                <v-img :src="form.businesslicensePhoto" @click="showImg(form.businesslicensePhoto)"></v-img>
                <p class="text-center caption">营业执照</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="creatDialog = false">
            取消
          </v-btn>
          <v-btn color="blue darken-1" text @click="pass(form.username)">
            通过
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showImgDialog" max-width="500">
      <v-img :src="img"></v-img>
    </v-dialog>
  </v-container>
</template>
<script>
// let vm;
import axios from "axios"; //基于Promise的HTTP客户端
// //import qs from "qs"
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.104:64011/";
// let version = "";
export default {
  data () {
    return {
      title: "认证审批",
      userList: [],
      formType: true, // true-创建 false-修改
      creatDialog: false,
      showImgDialog: false,
      // 添加用户表单
      form: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phoneNumber: "",
        idCard: "",
        name: "",
        companyName: "",
        businessLicense: "",
        frontPhoto: "",
        backPhoto: "",
        businesslicensePhoto: ""
      },
      img: "", // 图片放大显示
      tableHeaders: [
        {
          text: "用户名",
          align: "start",
          sortable: false,
          value: "username"
        },
        { text: "电话", sortable: false, value: "phoneNumber" },
        { text: "邮箱", sortable: false, value: "email" },
        { text: "时间", sortable: false, value: "applyTime" },
        { text: "账号类型", sortable: true, value: "type" },
        { text: "账号权限", sortable: true, value: "accountType" },
        { text: "操作", sortable: false, value: "actions" }
      ],
      searchUser: ""
    };
  },
  async created () {
    this.getApplyList();
  },
  props: ["role", "username", "invitationCode"],
  methods: {
    // 获取用户身份认证
    getApplyList () {
      axios.get(`${commonUrl}listAuthApply${version}`).then(res => {
        console.log(res);
        this.userList = res.data.result;
      });
    },
    getType (type) {
      if (type == 0) {
        return "个人用户";
      } else if (type == 1) {
        return "企业用户";
      } else {
        return "";
      }
    },
    getAccountType (type) {
      if (type == "customer") {
        return "用户";
      } else if (type == "projectAdmin") {
        return "项目管理员";
      } else {
        return "";
      }
    },
    paresTime (time) {
      return new Date(time).format("yyyy-MM-dd hh:mm:ss");
    },
    createUser () {
      this.form = {};
      this.creatDialog = true;
      this.formType = true;
    },
    showImg (img) {
      this.showImgDialog = true;
      this.img = img;
    },
    // 查看用户申请资料
    editItem (item) {
      axios
        .get(`${commonUrl}listOneAuth${version}?username=` + item.username)
        .then(res => {
          this.form = {
            username: res.data.result.username,
            email: res.data.result.email,
            phoneNumber: res.data.result.phoneNumber,
            name: res.data.result.name,
            idCard: res.data.result.idCard,
            companyName: res.data.result.companyName,
            businessLicense: res.data.result.businessLicense,
            frontPhoto:
              res.data.result.frontPhotoPath == null
                ? null
                : "data:image/jpeg;base64," + res.data.result.frontPhotoPath,
            backPhoto:
              res.data.result.backPhotoPath == null
                ? null
                : "data:image/jpeg;base64," + res.data.result.backPhotoPath,
            businesslicensePhoto:
              res.data.result.businessLicensePath == null
                ? null
                : "data:image/jpeg;base64," +
                res.data.result.businessLicensePath
          };
          console.log(this.form);
          this.formType = false;
          this.creatDialog = true;
        });
    },
    pass (username) {
      var formData = new FormData();
      formData.append("username", username);
      axios.post(`${commonUrl}passAuthApply${version}`, formData).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.$emit("tips", "审核成功", "green");
          this.creatDialog = false;
          this.getApplyList();
        } else if (res.data.message != null) {
          this.$emit("tips", res.data.message, "red");
        } else {
          this.$emit("tips", "审核失败", "red");
        }
      });
    },
    refuse (username) {
      this.$dialog
        .confirm({
          text: "是否拒绝此用户申请",
          title: "拒绝申请",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            var formData = new FormData();
            formData.append("username", username);
            axios
              .post(`${commonUrl}refuseAuthApply${version}`, formData)
              .then(res => {
                if (res.data.code == 200) {
                  this.$emit("tips", "拒绝成功", "green");
                  this.creatDialog = false;
                  this.getApplyList();
                } else if (res.data.message != null) {
                  this.$emit("tips", res.data.message, "red");
                } else {
                  this.$emit("tips", "拒绝失败", "red");
                }
              });
          }
        });
    }
  }
};
</script>
